<!--消费模块--报表数据-->
<template>
  <div
    id="reportData"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a90") }}</my-headertitle>
    </div>
    <div class="main">
      <div class="container">
        <div class="title">{{ $t("consume.a91") }}</div>
        <div class="container_set">
          <ul>
            <li>{{ $t("consume.a92") }}</li>
            <li>{{ $t("consume.a93") }}</li>
            <li>{{ $t("consume.a94") }}</li>
          </ul>
          <ul>
            <li>
              <a-select v-model="selectValue" style="width: 300px">
                <a-select-option
                  v-for="(itme, index) in selectlist"
                  :key="index"
                  :value="itme.siteId"
                >
                  {{ itme.location }}
                </a-select-option>
              </a-select>
            </li>
            <li>
              <a-radio-group name="radioGroup" v-model="time">
                <a-radio :value="1"> {{ $t("consume.a95") }} </a-radio>
                <a-radio :value="2"> {{ $t("consume.a96") }} </a-radio>
                <a-radio :value="3"> {{ $t("consume.a97") }} </a-radio>
              </a-radio-group>
            </li>
            <li>
              <a-date-picker
                v-model="beginDate"
                @change="handleCalendarChange"
              />
              <span class="interim">~</span>
              <span v-if="time !== 3">{{ endDate }}</span>
              <a-date-picker v-model="endTime" v-else />
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <div class="title">{{ $t("consume.a98") }}</div>
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            {{ $t("consume.a99") }}
          </a-checkbox>
        </div>
        <br />
        <a-checkbox-group
          v-model="checkedList"
          :options="plainOptions"
          @change="onChange"
        />
      </div>
    </div>
    <div class="floor">
      <div class="floor_left">
        <div class="floor_title">
          <span>{{ $t("consume.a100") }}</span>
        </div>
        <div class="floor_output" v-if="show">
          <a-icon type="sync" spin />
          <span>{{ $t("consume.a101") }}</span>
        </div>
        <div class="floor_download" v-if="filendownload">
          <span>{{ filename }}</span>
          <a-icon
            type="download"
            @click="download"
            :style="{
              color: '#7682ce',
              'font-size': '20px',
              'margin-left': '15px',
            }"
          />
        </div>
      </div>
      <div>
        <a-button
          type="primary"
          @click="output"
          :disabled="checkedList.length == 0"
          >{{ $t("consume.a102") }}</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import headerTitle from "../../components/Title/headerTitle";
import { getCanteen, report } from "../../api/consume";
import moment from "moment";

export default {
  name: "reportData",
  data() {
    return {
      selectlist: [],
      selectValue: undefined,
      beginDate: moment(),
      endDate: "",
      endTime: undefined,
      time: 1,
      filendownload: false,
      show: false,
      disabled: false,
      loading: false,
      checkedList: ["1", "2", "3", "4", "5", "6"],
      filename: "",
      file: undefined,
      list: ["1", "2", "3", "4", "5", "6"],
      indeterminate: false,
      checkAll: true,
      plainOptions: [
        { label: this.$t("consume.a103"), value: "1" },
        { label: this.$t("consume.a104"), value: "2" },
        { label: this.$t("consume.a105"), value: "3" },
        { label: this.$t("consume.a106"), value: "4" },
        { label: this.$t("consume.a107"), value: "5" },
        { label: this.$t("consume.a108"), value: "6" },
      ],
    };
  },
  watch: {
    time: {
      handler(newVaule) {
        let value = newVaule;
        switch (value) {
          case 1:
            const beginDate = moment().subtract(1, "months").add(1, "day");
            const endDate = moment();
            this.beginDate = beginDate;
            this.endDate = endDate.format("YYYY-MM-DD");
            break;
          case 2:
            this.beginDate = moment();
            this.endDate = moment().format("YYYY-MM-DD");
            break;
          case 3:
            this.beginDate = moment();
            this.endTime = moment();
            break;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loading = true;
    getCanteen()
      .then((res) => {
        console.log(res);
        this.loading = false;
        const { data } = res;
        if (data.length !== 0) {
          this.selectlist = data;
          this.selectValue = Number(this.$route.query.id);
        }
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    moment,
    // 初始时间选择后结束时间设置
    handleCalendarChange(value) {
      console.log(value);
      const pattern = this.time;
      if (pattern == 1) {
        const _val = JSON.stringify(value);
        const val = JSON.parse(_val);
        this.endDate = moment(val).add(1, "months").format("YYYY-MM-DD");
      } else if (pattern == 2) {
        const _val = JSON.stringify(value);
        const val = JSON.parse(_val);
        this.endDate = moment(val).add(1, "day").format("YYYY-MM-DD");
      }
    },
    download() {
      let url = window.URL.createObjectURL(this.file);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", this.filename);
      document.body.appendChild(link);
      link.click();
    },
    output() {
      this.show = true;
      this.filendownload = false;
      let value = this.time;
      let body = {};
      let query = {};
      if (value == 1 || value == 2) {
        body.startDate = this.beginDate.format("YYYY-MM-DD");
        body.endDate = this.endDate;
        body.reportType = this.checkedList;
        query.siteId = this.selectValue;
      } else {
        body.startDate = this.beginDate.format("YYYY-MM-DD");
        body.endDate = this.endTime.format("YYYY-MM-DD");
        body.reportType = this.checkedList;
        query.siteId = this.selectValue;
      }
      console.log(body, query);
      report(body, query)
        .then((res) => {
          console.log(res);
          this.show = false;
          this.filendownload = true;
          this.filename = decodeURIComponent(res.headers["content-disposition"])
            .split(";")[1]
            .split("=")[1];
          this.file = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.show = false;
        });
    },
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.list : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
#reportData {
  height: 100%;
  padding: 0px 20px;
}
.main {
  padding-top: 20px;
  height: calc(100% - 111px);
}
.container {
  position: relative;
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid rgba(12, 12, 12, 0.2);
}
.title {
  position: absolute;
  padding: 0px 5px;
  font-size: 14px;
  top: -12px;
  left: 15px;
  background-color: #fff;
}
ul {
  list-style: none;
  margin: 0px 20px 0px 0px;
}
li {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.container_set {
  display: flex;
}
.interim {
  font-size: 20px;
  margin: 0px 15px;
}
.floor {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.floor_left {
  display: flex;
}
.floor_title {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
  background: #7682ce;
}
.floor_download {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;
}
.floor_output {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  font-size: 15px;
  margin-left: 15px;
}
</style>
